'use client'
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createRef
} from 'react'
import Icon from './Icon'

const ImageItem = React.memo(
  ({ id, images, cdn, noLoading, selected, title }) => {
    const [loaded, setLoaded] = useState(false)
    const loadingCounter = useRef(0)
    const imageRef = useRef(
      images.filter(i => Boolean(i?.url)).map(() => createRef())
    )

    const imageLoaded = useCallback(() => {
      loadingCounter.current += 1
      if (
        loadingCounter.current >= images.filter(i => Boolean(i?.url)).length
      ) {
        setLoaded(true)
      }
    }, [])

    useEffect(() => {
      if (imageRef.current) {
        imageRef.current.forEach(i => {
          if (i.current?.complete) imageLoaded()
        })
      }
      return () => {
        setLoaded(false)
        loadingCounter.current = 0
      }
    }, [images])

    return (
      <div className="relative w-full h-full">
        {!loaded && !noLoading && cdn !== undefined && (
          <div
            className="w-full h-full absolute z-10 flex justify-center items-center"
            style={{
              backgroundColor: 'white'
            }}
          >
            <Icon
              cdn={cdn}
              url={'/asset/images/loading-icon.svg'}
              style={{ width: '50%', maxWidth: '200px' }}
            />
          </div>
        )}

        {images
          .filter(i => Boolean(i?.url))
          .map((i, index) => {
            const {
              width,
              deltaX,
              deltaY,
              defaultWidth,
              defaultHeight,
              url,
              imgWidth,
              imgHeight
            } = i
            let transform = ''
            if (width) {
              transform = `${transform} scale(${width / (imgWidth ?? defaultWidth)})`
            }
            if (deltaX) {
              transform = `${transform} translateX(${(deltaX / (imgWidth ?? defaultWidth)) * 100}%)`
            }
            if (deltaY) {
              transform = `${transform} translateY(${(deltaY / (imgHeight ?? defaultHeight)) * 100}%)`
            }
            return (
              <div className="absolute w-full h-full" key={index}>
                <div
                  style={{
                    height: '100%',
                    width: 'fit-content',
                    margin: 'auto',
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  {selected && index === 0 && (
                    <Icon
                      cdn={cdn}
                      url={'/asset/images/control/checked.svg'}
                      style={{
                        position: 'absolute',
                        zIndex: '30',
                        width: '30%'
                      }}
                    />
                  )}
                  <img
                    src={url}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      width: 'auto',
                      height: 'auto',
                      margin: 'auto',
                      transform: transform,
                      transformOrigin: 'top left'
                    }}
                    ref={imageRef.current[index]}
                    onLoad={imageLoaded}
                    alt={title}
                  />
                </div>
              </div>
            )
          })}
      </div>
    )
  },
  (prev, next) => {
    return (
      prev.id === next.id &&
      prev.images === next.images &&
      prev.cdn === next.cdn &&
      prev.noLoading === next.noLoading &&
      prev.selected === next.selected
    )
  }
)

ImageItem.displayName = 'ImageItem'

export default ImageItem
