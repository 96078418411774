'use client'

import ImageItem from '@/components/shared/imageItem'
import Font from '@/fonts/font'
import Link from 'next/link'
import useScrolling from '@/lib/hooks/useScrolling'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

export default function CollectionContent({
  themes,
  targetPhone,
  resizer,
  settings
}) {
  const {
    containerRef,
    itemsRef,
    firstVisible,
    lastVisible,
    scrollPrev,
    scrollNext
  } = useScrolling([themes.length])

  return (
    <div className="w-full relative">
      {!firstVisible && (
        <div
          className="absolute top-0 left-0 h-full flex items-center z-10"
          onClick={() => {
            scrollPrev()
          }}
        >
          <LeftCircleOutlined
            className="text-3xl rounded-full"
            style={{ background: 'rgba(255,255,255,.7)', color: '#606060' }}
          />
        </div>
      )}
      <div
        className="flex mt-12 md:mt-24 justify-start overflow-x-auto flex-no-wrap md:flex-wrap mx-3 md:mx-0 relative"
        ref={containerRef}
      >
        {themes.map((c, index) => {
          const targetResize =
            resizer.specific.find(
              s =>
                s.phoneCaseThemeId === c.node.id &&
                s.phoneCaseId === targetPhone.id
            ) ??
            resizer.phoneCaseDefaults.find(
              p => p.phoneCaseId === targetPhone.id
            ) ??
            resizer.themeDefaults.find(t => t.phoneCaseThemeId === c.node.id)
          const resizeValue = {
            ...(targetResize?.value ?? {}),
            ...settings.theme
          }
          return (
            <div
              className={`basis-1/4 cursor-pointer shrink-0 text-center mt-4 mx-3 md:mx-0`}
              key={`collection-${index}`}
              ref={e => {
                itemsRef.current[`collection-${index}`] = e
              }}
            >
              <Link href={`/theme/detail/${c.node.handle}`} className="w-full" title={c.node.title}>
                <div className="w-full h-[12rem] md:h-[26rem] relative overflow-hidden">
                  <div className="w-full h-[15rem] md:h-[30rem] relative">
                    <ImageItem
                      id={c.node.id}
                      title={c.node.title}
                      images={[
                        {
                          url: targetPhone.metaImages?.reference?.baseImage
                            ?.reference?.image?.url
                        },
                        {
                          url: c.node.metaImages?.reference?.background
                            ?.reference?.image?.url,
                          imgWidth:
                            c.node.metaImages?.reference?.background?.reference
                              ?.image?.width,
                          imgHeight:
                            c.node.metaImages?.reference?.background?.reference
                              ?.image?.height,
                          ...resizeValue
                        },
                        {
                          url: c.node.metaImages?.reference?.subject?.reference
                            ?.image?.url,
                          imgWidth:
                            c.node.metaImages?.reference?.subject?.reference
                              ?.image?.width,
                          imgHeight:
                            c.node.metaImages?.reference?.subject?.reference
                              ?.image?.height,
                          ...resizeValue
                        },
                        {
                          url: c.node.metaImages?.reference?.foreground
                            ?.reference?.image?.url,
                          imgWidth:
                            c.node.metaImages?.reference?.foreground?.reference
                              ?.image?.width,
                          imgHeight:
                            c.node.metaImages?.reference?.foreground?.reference
                              ?.image?.height,
                          ...resizeValue
                        },
                        {
                          url: targetPhone.metaImages?.reference?.coverImage
                            ?.reference?.image?.url
                        }
                      ]}
                      cdn={settings.cdn}
                    />
                  </div>
                </div>
                <span
                  className={`${Font.Myriad} text-center font-bold text-lg md:text-xl`}
                  style={{ color: '#474747' }}
                >
                  {c.node.title}
                </span>
              </Link>
            </div>
          )
        })}
      </div>
      {!lastVisible && (
        <div
          className="absolute top-0 right-0 h-full flex items-center z-10"
          onClick={() => {
            scrollNext()
          }}
        >
          <RightCircleOutlined
            className="text-3xl rounded-full"
            style={{ background: 'rgba(255,255,255,.7)', color: '#606060' }}
          />
        </div>
      )}
    </div>
  )
}
