import { useEffect, useRef, useState, useCallback } from 'react'

export default function useScrolling(dependency) {
  const containerRef = useRef()
  const itemsRef = useRef({})
  const observeRef = useRef([])
  const itemViewRef = useRef([])
  const [firstVisible, setFirstVisible] = useState(false)
  const [lastVisible, setLastVisible] = useState(false)

  useEffect(() => {
    return () => {
      observeRef.current.forEach(o => {
        o.disconnect()
      })
    }
  }, [])

  useEffect(() => {
    const elements = Object.keys(itemsRef.current).filter(k =>
      Boolean(itemsRef.current[k])
    )
    itemViewRef.current = Array(elements.length).fill(null)
    for (let i = 0; i < elements.length; i++) {
      const observer = new IntersectionObserver(
        entry => {
          itemViewRef.current[i] = entry[0].isIntersecting
          if (
            itemViewRef.current.filter(iv => iv !== null).length ===
            elements.length
          ) {
            if (itemViewRef.current[0]) {
              setFirstVisible(true)
            } else {
              setFirstVisible(false)
            }
            if (itemViewRef.current[elements.length - 1]) {
              setLastVisible(true)
            } else {
              setLastVisible(false)
            }
          }
        },
        {
          root: containerRef.current,
          threshold: 1.0
        }
      )
      observer.observe(itemsRef.current[elements[i]])
      observeRef.current.push(observer)
    }
    return () => {
      observeRef.current.forEach(o => {
        o.disconnect()
      })
      observeRef.current = []
      setFirstVisible(false)
      setLastVisible(false)
    }
  }, [...dependency])

  const scrollPrev = useCallback(() => {
    const target = itemViewRef.current.findIndex(iv => iv === true) - 1
    if (target > 0) {
      const elements = Object.keys(itemsRef.current).filter(k =>
        Boolean(itemsRef.current[k])
      )
      itemsRef.current[elements[target]].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end'
      })
    }
  }, [])

  const scrollNext = useCallback(() => {
    const target = itemViewRef.current.findLastIndex(iv => iv === true) + 1
    const elements = Object.keys(itemsRef.current).filter(k =>
      Boolean(itemsRef.current[k])
    )
    if (target < elements.length) {
      itemsRef.current[elements[target]].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      })
    }
  }, [])

  return {
    containerRef,
    itemsRef,
    firstVisible,
    lastVisible,
    scrollPrev,
    scrollNext
  }
}
